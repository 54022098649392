/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-promise-reject-errors */
// API request usign Axios library

import axios from 'axios'

const apiEndpoint = 'https://inlabapi.innpressionhost.com/develop/request.php';

export default {
  install(Vue, options) {
    Vue.prototype.$XHR = {
      // POST API request
      post(payload, uploadProgress = null) {
        return new Promise((resolve, reject) => {
          const bodyFormData = new FormData()

          for (const e in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, e)) {
              bodyFormData.append(e, payload[e])
            }
          }

          const config = {
            method: 'post',
            url: apiEndpoint,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
          }

          if (uploadProgress != null) {
            config.onUploadProgress = uploadProgress
          }

          axios(config).then(response => {
            // handle server response
            if (response.data !== undefined && response.data.result !== undefined) {
              if (response.data.result === 'success') {
                resolve(response.data)
              } else {
                console.error('[API] POST failed (non-successful result)')
                reject(response.data)
              }
            } else if (response.data !== undefined && Array.isArray(response.data)) {
              if (response.data[0]) {
                if (response.data[1].status === 'file uploaded and optimized' || response.data[1].status === 'file uploaded') {
                  resolve(response.data[1].filename)
                } else {
                  console.error('[API] POST failed (non-successful result)')
                  reject(response.data)
                }
              } else {
                console.error('[API] POST failed (non-successful result)')
                reject(response.data)
              }
            } else {
              console.error('[API] POST failed (missing data or result)')
              reject(null)
            }

          }).catch(response => {
            // handle error
            console.error('[API] POST failed (connection exception)')
            reject(null)
          })
        })
      },

      async api(key, passObj) {
        const params = new URLSearchParams();
        params.set(key, JSON.stringify(passObj));
        const response = await axios.post(apiEndpoint, params.toString());
        const result = response.data;
        if (result.result === 'success' && result.data !== undefined) {
          if (result.total !== undefined && result.total !== null && result.total !== '') {
            return Promise.resolve({ data: result.data, total: result.total });
          }

          return Promise.resolve(result.data);
        } else if (result.result === 'fail') {
          return Promise.reject({
            "result": "fail",
            "data": typeof result.data === 'string' ? result.data.trim() : JSON.stringify(result.data)
          })
        }

        return Promise.reject({
          "result": "fail",
          "data": "Error Occur. Please contact our staffs."
        })
      },
    }
  },
}
