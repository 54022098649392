<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" v-if="showBackButton">
        <v-btn depressed color="black" text @click="$router.back()" class="mr-2" :disabled="isLoading">
          <v-icon left class="back-button-icon">{{ mdiChevronLeft }}</v-icon>
          Back
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex justify-end" v-if="showRightSlot">
        <slot name="rightSlot"></slot>
      </v-col>

      <v-col cols="12" class="mt-6" v-if="$validate.DataValid(pageTitle) && $route.meta.showTitleAtSlot">
        <h3>{{ pageTitle }}</h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
export default {
  name: 'PageHeaderSlot',
  props: {
    showBackButton: {
      require: false,
      type: Boolean,
      default: false,
    },
    isLoading: {
      require: false,
      type: Boolean,
      default: false,
    },
    showRightSlot: {
      require: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiChevronLeft,
  }),
  computed: {
    pageTitle: function () {
      let result = ''

      if (this.$route.meta.title !== undefined && this.$route.meta.showTitleAtSlot) {
        result = this.$route.meta.title
      }

      return result
    },
  },
}
</script>

<style lang="scss" scoped>

.back-button-icon {
  font-size: 14px !important;
}

.top-right-class {
  /* d-flex align-center my-2 ml-2*/
  display: flex;
  align-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.bottom-left-class {
  //d-flex align-center
  display: flex;
  align-content: center;
}

.bottom-right-class {
  // d-flex align-center
  display: flex;
  align-content: center;
}
</style>