import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import api from './api'
import TokenMixin from '@/mixins/TokenMixin'
import PageHeaderSlot from './components/PageHeaderSlot.vue'
import Fetcher from './prototype/Fetcher'
import CommonFn from './prototype/CommonFn'
import Formatter from './prototype/Formatter'
import Validator from './prototype/Validator'

Vue.config.productionTip = false

Vue.use(api)
Vue.use(CommonFn)
Vue.use(Validator)
Vue.use(Formatter)
Vue.use(Fetcher)
Vue.mixin(TokenMixin)

Vue.component('PageHeaderSlot', PageHeaderSlot)

Vue.prototype.$mediaPath = 'http://inlabmedia.innpressionhost.com/';

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
