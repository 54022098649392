<template>
    <v-container class="alert-pop">
      <v-alert width="250px" v-model="showStatus" type="success" transition="slide-x-reverse-transition">
        Uploading... ({{ progressPercentage }}%)
      </v-alert>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'AlertBox',
    props: ['show', 'progress'],
    data: () => ({
      showStatus: false,
      timeout: -1,
      timeOutClock: null,
      alert_type: 'success',
      progressPercentage: 0,
    }),
    watch: {
      show: function (newVal, oldVal) {
        this.showStatus = newVal
        /*       if (this.timeOutClock != null) {
          clearTimeout(this.timeOutClock)
        }
  
        this.timeOutClock = setTimeout(() => {
          this.closeAlertBox()
        }, 2000) */
      },
      progress: function (newVal, oldVal) {
        this.progressPercentage = parseInt(newVal)
      },
    },
    methods: {
      /*    closeAlertBox() {
        this.showStatus = false
        this.$emit('close', this.showStatus)
        clearTimeout(this.timeOutClock)
        this.timeOutClock = null
      }, */
    },
    /*   beforeDestroy() {
      if (this.timeOutClock != null) {
        clearTimeout(this.timeOutClock)
      }
    }, */
  }
  </script>