const state = {
    showLoadingPage: false, // control show top loading bar or not
    showOverlay: false, // control show overlay or not
    alertMessage: { // AlertBox props 
        show: false,
        message: '',
        type: 'success',
        refresh: false,
        redirect: '',
    },
    isLoading: false, // tells submission/upload is processing or not
    isUploading: false, // tells upload is processing or not
    dataIsUpdated: false, // tells details is edited without saving or not
    uploadProgress: 0,
    showUploadAlert: false,
}

const getters = {
    showLoadingPage: state => state.showLoadingPage,
    alertMessage: state => state.alertMessage,
    showOverlay: state => state.showOverlay,
    isLoading: state => state.isLoading,
    isUploading: state => state.isUploading,
    dataIsUpdated: state => state.dataIsUpdated,
    uploadProgress: state => state.uploadProgress,
    showUploadAlert: state => state.showUploadAlert,
}

const actions = {
    toggleLoadingPage({ commit }, data = false) {
        commit('setLoadingPage', data)
    },
    toggleAlertMessage({ commit }, data = null) {
        if (data === null) {
            commit('toggleAlert', {
                show: false,
                message: '',
                type: 'success',
                refresh: false,
                redirect: ''
            })
        } else {
            const passData = JSON.parse(JSON.stringify(data));
            switch (data.message) {
                case 'processing':
                    passData.message = 'Data processing. Please wait...';
                    break;
                case 'required':
                    passData.message = 'Please fill in all required field and make sure the correct format';
                    break;
                case 'invValue':
                    passData.message = 'Please make sure the correct format';
                    break;
                case 'noPermission':
                    passData.message = 'You do not have permission to proceed the action';
                    break;
            }
            commit('toggleAlert', passData)
        }
    },
    toggleOverlay({ commit }, data = false) {
        commit('setOverlay', data)
    },
    setLoading({ commit }, data = false) {
        commit('setLoading', data)
    },
    setUploadLoading({ commit }, data = false) {
        commit('setUploadLoading', data)
    },
    setDataIsUpdated({ commit }, data = false) {
        commit('setDataIsUpdated', data)
    },
    controlUploadAlert({ commit }, data = null) {
        if (data === null) {
            commit('toggleUploadAlert', { show: false, progress: 0 })
        } else {
            commit('toggleUploadAlert', data)
        }
    },
}

const mutations = {
    setLoadingPage: function (state, bool) {
        state.showLoadingPage = bool
    },
    toggleAlert: function (state, data) {
        state.alertMessage = data
    },
    setOverlay: function (state, bool) {
        state.showOverlay = bool
    },
    setLoading: function (state, bool) {
        state.isLoading = bool;
    },
    setUploadLoading: function (state, bool) {
        state.isUploading = bool
    },
    setDataIsUpdated: function (state, bool) {
        state.dataIsUpdated = bool
    },
    toggleUploadAlert: function (state, data) {
        state.showUploadAlert = data.show
        state.uploadProgress = data.progress
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}