const displayError = (res) => {
    if (res.data !== undefined && res.data !== null) {
        if (typeof res.data === 'string') {
            return res.data;
        } else {
            return JSON.stringify(res.data);
        }
    }

    return JSON.stringify(res);
}

export default {
    install(Vue, options) {
        /**
         * get_all_data_of_type
         * @param {Number} filter_page - start from 0
         * @param {Number} filter_limit
         * @param {String} status
         * @param {String} sort_dir - 'desc' | 'asc'
         * @param {String} sort_key
         * @param {String} search_key - used with 'search_value'
         * @param {String} search_value - used with 'search_key'
         */


        Vue.prototype.$Fetcher = new (function () {
            const DataValid = Vue.prototype.$validate.DataValid;
            const devLog = Vue.prototype.$common.log;

            this.Login = async function (username, password) {
                try {
                    const data = await Vue.prototype.$XHR.api('cms_staff_login', {
                        username, password
                    });
                    devLog('----------> XHR [SUCCESS]: Login');
                    devLog(data)
                    return Promise.resolve({
                        id: data.id,
                        username: data.username,
                        user_type: data.role,
                        last_login: data.last_login
                    });
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Login');
                    devLog(res);
                    switch (res.data) {
                        case 'login fail':
                            return Promise.reject('Wrong username/password');
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.GetHomeData = async function () {
                try {
                    const data = await Vue.prototype.$XHR.api('get_home_setting', {});
                    devLog('----------> XHR [SUCCESS]: GetHomeData');
                    devLog(data);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetHomeData');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.SetHomeData = async function (uploadedData) {
                try {
                    const payload = {
                        ...uploadedData
                    };
                    const data = await Vue.prototype.$XHR.api('cms_set_home_setting', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: SetHomeData');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: SetHomeData');
                        return Promise.reject("Error Occur. Please contact our staffs.");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SetHomeData');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetServiceData = async function () {
                try {
                    const data = await Vue.prototype.$XHR.api('get_service_setting', {});
                    devLog('----------> XHR [SUCCESS]: GetServiceData');
                    devLog(data);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetServiceData');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.SetServiceData = async function (uploadedData) {
                try {
                    const payload = {
                        ...uploadedData
                    };
                    const data = await Vue.prototype.$XHR.api('cms_set_service_setting', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: SetServiceData');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: SetHomeData');
                        return Promise.reject("Error Occur. Please contact our staffs.");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SetServiceData');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetMaterialsData = async function () {
                try {
                    const data = await Vue.prototype.$XHR.api('get_materials_setting', {});
                    devLog('----------> XHR [SUCCESS]: GetMaterialsData');
                    devLog(data);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetMaterialsData');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.SetMaterialsData = async function (uploadedData) {
                try {
                    const payload = {
                        ...uploadedData
                    };
                    const data = await Vue.prototype.$XHR.api('cms_set_materials_setting', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: SetMaterialsData');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: SetMaterialsData');
                        return Promise.reject("Error Occur. Please contact our staffs.");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SetMaterialsData');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetAboutData = async function () {
                try {
                    const data = await Vue.prototype.$XHR.api('get_about_setting', {});
                    devLog('----------> XHR [SUCCESS]: GetAboutData');
                    devLog(data);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAboutData');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.SetAboutData = async function (uploadedData) {
                try {
                    const payload = {
                        ...uploadedData
                    };
                    const data = await Vue.prototype.$XHR.api('cms_set_about_setting', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: SetAboutData');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: SetAboutData');
                        return Promise.reject("Error Occur. Please contact our staffs.");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SetAboutData');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetNews = async function (filters = {}) {
                const payload = {
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_news_list', payload);

                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetNews');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetNews');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetNews');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.DeleteNewsById = async function (id) {
                try {
                    const payload = {
                        id,
                    };
                    const data = await Vue.prototype.$XHR.api('cms_remove_news', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: DeleteNewsById');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: DeleteNewsById');
                        return Promise.reject("Error Occur. Please contact our staffs.");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteNewsById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.AddNews = async function (uploadedData) {
                if (DataValid(uploadedData['status'])) {
                    delete uploadedData['status'];
                }

                try {
                    const payload = {
                        ...uploadedData,
                    };
                    const data = await Vue.prototype.$XHR.api('cms_add_news', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: AddNews');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: AddNews');
                        return Promise.reject("Error Occur. Please contact our staffs.");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewData');
                    devLog(res);
                    switch(res.data) {
                        case 'repeated slug':
                            return Promise.reject('URL Slug has been used. Please use another slug.');
                        default:
                            return Promise.reject(displayError(res));
                    } 
                }
            }

            this.GetNewsById = async function (id) {
                const payload = {
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_news_by_id', payload);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetNewsById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateNews = async function (id, uploadedData) {
                if (DataValid(uploadedData['id'])) {
                    delete uploadedData['id'];
                }

                try {
                    const payload = {
                        id: id,
                        ...uploadedData
                    };
                    const data = await Vue.prototype.$XHR.api('cms_update_news', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: UpdateNews');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateNews');
                        return Promise.reject("Error Occur. Please contact our staffs.");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateNews');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetSubscriptions = async function (filters = {}) {
                const payload = {
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_subscription_list', payload);

                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetSubscriptions');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetSubscriptions');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSubscriptions');
                    devLog(res);
                    return Promise.reject([])
                }
            }
        });
    },
}