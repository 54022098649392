export default {
    install(Vue, option) {
        Vue.prototype.$common = new (function () {
            const isDev = (window.location.hostname).includes('localhost') || (window.location.hostname).includes('innpressionhost');

            this.log = value => {
                if (isDev) {
                    console.log(value)
                }
            }

            this.error = value => {
                if (isDev) {
                    console.error(value)
                }
            }

            this.logData = (data, title = '') => {
                if (isDev) {
                    if (title !== '') {
                        console.log(`------------- ${title} -------------`);
                    }
                    console.log(JSON.parse(JSON.stringify(data)));
                }
            }

            this.duplicateData = data => {
                return JSON.parse(JSON.stringify(data));
            }

            // ============ File Function =================
            this.getAcceptFileFormat = type => {
                let fileFormatStr = "";
                let fileFormatArr = [];

                if (type === 'image') {
                    fileFormatStr = "image/jpeg,image/jpg,image/png"
                    fileFormatArr = ['png', 'jpeg', 'jpg'];
                } else if (type === 'video') {
                    fileFormatStr = "video/mp4,video/webm"
                    fileFormatArr = ['mp4', 'webm'];
                } else if (type === 'imageWithPDF') {
                    fileFormatStr = "image/jpeg,image/jpg,image/png,application/pdf"
                    fileFormatArr = ['png', 'jpeg', 'jpg', 'pdf'];
                } else if (type === 'imageGifOnly') {
                    fileFormatStr = "image/gif"
                    fileFormatArr = ['gif'];
                } else if (type === 'imageIcon') {
                    fileFormatStr = "image/png,image/svg+xml,image/svg"
                    fileFormatArr = ['png', 'svg'];
                } else if (type === 'pdfOnly') {
                    fileFormatStr = "application/pdf"
                    fileFormatArr = ['pdf'];
                }

                if (Vue.prototype.$validate.DataValid(fileFormatStr) && Vue.prototype.$validate.DataValid(fileFormatArr)) {
                    return {
                        formatStr: fileFormatStr,
                        formatArr: fileFormatArr
                    }
                }

                return null;
            }

            this.formatUploadProgress = (value, total) => {
                return ((value / total) * 100).toFixed(2)
            }


            // ============ Data Process Function =================
        })()
    }
}