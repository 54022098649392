/**
 * user_type：
 */

export const cannotAccessList = {
  
}

export const defaultPage = {
  developer: '/home',
  admin: '/home',
}
